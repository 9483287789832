import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  LinearProgress,
  Stack,
} from '@mui/material';

import Config from '../shared/Config';
import { useAuth } from 'oidc-react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import useServices from '../hooks/useServices';

AddResponder.propTypes = {
  item: PropTypes.object.isRequired,
};

function AddResponder({ item }) {
  const i = item;
  const [servicesValue] = React.useState([]);
  const [showAddResponderDialog, setShowAddResponderDialog] = React.useState(false);
  const [progressShow, setProgressShow] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('Request to add responder submitted successfully.');
  const [successShow, setSuccessShow] = React.useState(false);
  const [warningMessage, setWarningMessage] = React.useState('Sorry. An error occurred while trying to add responder.');
  const [warningShow, setWarningShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('Sorry. An error occurred while trying to add responder.');
  const [errorShow, setErrorShow] = React.useState(false);
  const { servicesSearchResults } = useServices();

  const auth = useAuth();
  const { puffIncidentUrl } = Config();

  const handleAddResponderClickOpen = () => {
    setShowAddResponderDialog(true);
  };

  const handleAddResponderClickClose = () => {
    setShowAddResponderDialog(false);
  };

  const handleAddResponderSubmit = (event) => {
    event.preventDefault();
    setShowAddResponderDialog(false);
    setProgressShow(true);

    const listOfServices = [];
    for (var i = 0; i < servicesValue.length; i++) {
      listOfServices.push(servicesValue[i]['service_name']);
    }
    const listOfConversations = [];

    const body = {
      call_origin: 'gostatus',
      user: { id: '', email: auth.userData?.profile?.email },
      incident: { public_id: item.data.attributes.public_id, conversations: listOfConversations, services: listOfServices },
    };
    const addResponderEndpoint = `${puffIncidentUrl}/addresponder`;

    if (listOfServices.length > 0 && listOfServices.length <= 5) {
      try {
        fetch(addResponderEndpoint, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            token: auth.userData?.access_token,
          },
        }).then((response) => {
          if (response.status == 201) {
            setProgressShow(false);
            response
              .json()
              .then((data) => ({ data: data, status: response.status }))
              .then((resp) => {
                setSuccessMessage(`${resp.data.message}`);
              });
            setSuccessShow(true);
          } else if (response.status == 400) {
            setWarningShow(true);
            setProgressShow(false);

            response
              .json()
              .then((data) => ({ data: data, status: response.status }))
              .then((resp) => {
                setWarningMessage(`${resp.status} - ${resp.data.message}`);
              });
          } else {
            setErrorShow(true);
            setProgressShow(false);
            response
              .json()
              .then((data) => ({ data: data, status: response.status }))
              .then((resp) => {
                setErrorMessage(`${resp.status} - ${resp.data.message}`);
              });
          }
        });
      } catch (e) {
        setErrorShow(true);
        setErrorMessage(`${e}`);
        setProgressShow(false);
        console.log(e);
      }
    } else {
      setWarningMessage(`${listOfServices.length} services selected. 1-5 services allowed. Aborting.`);
      setWarningShow(true);
      setShowAddResponderDialog(false);
    }
    setProgressShow(false);
  };

  return (
    <Card elevation={0}>
      <Dialog
        open={showAddResponderDialog}
        onClose={handleAddResponderClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography component={'span'} variant="h5">
            Puff Incident
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Choose one or multiple (Max: 5) services to page.</span>
            <Autocomplete
              sx={{ mt: 3 }}
              multiple
              filterSelectedOptions
              id="tags-standard"
              getOptionLabel={(option) => option.service_name}
              options={servicesSearchResults}
              renderInput={(params) => <TextField {...params} label="Services" placeholder="" />}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddResponderClickClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleAddResponderSubmit}> Page </Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={2} sx={{ ml: 2 }}>
        {i.data.attributes.fields.state.value == 'resolved' || i.data.attributes.fields.state.value == 'completed' ? null : (
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleAddResponderClickOpen}
            startIcon={<NotificationsActiveOutlinedIcon />}
          >
            {' '}
            Add Responder{' '}
          </Button>
        )}
        {progressShow ? <LinearProgress color="inherit" /> : null}
        {successShow ? (
          <Alert variant="filled" severity="success">
            {' '}
            {successMessage}{' '}
          </Alert>
        ) : null}
        {warningShow ? (
          <Alert variant="filled" severity="warning">
            {' '}
            {warningMessage}{' '}
          </Alert>
        ) : null}
        {errorShow ? (
          <Alert variant="filled" severity="error">
            {' '}
            {errorMessage}{' '}
          </Alert>
        ) : null}
      </Stack>
    </Card>
  );
}

export default AddResponder;
