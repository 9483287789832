import React from 'react';
import PropTypes from 'prop-types';
import { AccountBox, Calculate, Dashboard, Home, Warning, Speed, LiveHelp } from '@mui/icons-material';
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { Link as RLink, useLocation } from 'react-router-dom';

AppDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};

function AppDrawer({ drawerOpen, setDrawerOpen }) {
  const { pathname } = useLocation();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItemButton component={RLink} to="/" selected={pathname === '/'} onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton
          target="_blank"
          href="https://gopuff.datadoghq.com/dashboard/q7e-t9k-78q?from_ts=1678907662391&to_ts=1678994062391&live=true"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="KPI Dashboard" />
        </ListItemButton>
        <ListItemButton component={RLink} to="/create-incident" selected={pathname === '/create-incident'} onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <Warning />
          </ListItemIcon>
          <ListItemText primary="Create Incident" />
        </ListItemButton>
        <ListItemButton target="_blank" href="https://gopuff.service-now.com/" onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <LiveHelp />
          </ListItemIcon>
          <ListItemText primary="ServiceNow" />
        </ListItemButton>
        <ListItemButton
          target="_blank"
          href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/3176333627/SRE+Uptime+Calculation"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Calculate />
          </ListItemIcon>
          <ListItemText primary="Uptime Calculation" />
        </ListItemButton>
        <ListItemButton
          target="_blank"
          href="https://gopuff.atlassian.net/wiki/spaces/ITOPS/pages/746815795/Severity+Levels"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Speed />
          </ListItemIcon>
          <ListItemText primary="Severity Levels" />
        </ListItemButton>
      </List>
      <Divider />
      <List subheader={<ListSubheader>Settings</ListSubheader>}>
        <ListItemButton component={RLink} to="/profile" selected={pathname === '/profile'} disabled onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </List>
    </Drawer>
  );
}

export default AppDrawer;
