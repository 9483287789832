import { useAuth } from 'oidc-react';
import Config from '../shared/Config';
import useServices from '../hooks/useServices';
import useRecentIncidents from '../hooks/useRecentIncidents';
import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  MenuItem,
  TextField,
  Typography,
  LinearProgress,
  Alert,
  Box,
} from '@mui/material';

function IncidentTrigger() {
  const auth = useAuth();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParamServicesCSV = urlSearchParams.get('services');
  const urlParamServices = urlParamServicesCSV ? urlParamServicesCSV.split(',') : null;
  const { servicesSearchResults } = useServices();
  const { dialogContentText, dialogTitle, openDialog, setOpenDialog } = useRecentIncidents();
  const [servicesValue, setServicesValue] = useState([]);
  const [state, setState] = useState({
    description: urlSearchParams.get('description') || '',
    severity: urlSearchParams.get('severity') || '',
    conversations: '',
    services: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const [buttonShow, setButtonShow] = useState(true);
  const [progressShow, setProgressShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState('Request to create a new incident submitted successfully.');
  const [successShow, setSuccessShow] = useState(false);
  const [warningMessage, setWarningMessage] = useState('Sorry. An error occurred while trying to create a new incident.');
  const [warningShow, setWarningShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Sorry. An error occurred while trying to create a new incident.');
  const [errorShow, setErrorShow] = useState(false);
  const [serviceAutofill, setServiceAutofill] = useState(false);
  const { puffIncidentUrl } = Config();

  useEffect(() => {
    if (!serviceAutofill && urlParamServices !== null && servicesSearchResults.length > 0) {
      setServiceAutofill(true);
      const serviceList = [...servicesValue];
      urlParamServices.forEach((urlParamService) => {
        const entry = servicesSearchResults.find((entry) => entry.service_name === urlParamService);
        if (entry === undefined) {
          console.error(`service does not exist: ${urlParamService}`);
          return;
        }
        serviceList.push(entry);
      });
      setServicesValue(serviceList);
    }
  }, [urlParamServices, servicesSearchResults, serviceAutofill, servicesValue]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonShow(!buttonShow);
    setProgressShow(!progressShow);

    const listOfServices = [];
    for (let i = 0; i < servicesValue.length; i++) {
      listOfServices.push(servicesValue[i]['service_name']);
    }

    const listOfConversations = state.conversations.split(',');
    const body = {
      call_origin: 'gostatus',
      user: { id: '', email: auth.userData?.profile?.email },
      incident: { description: state.description, severity: state.severity, conversations: listOfConversations, services: listOfServices },
    };
    const triggerIncidentEndpoint = `${puffIncidentUrl}/puffincident`;

    try {
      fetch(triggerIncidentEndpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          token: auth.userData?.access_token,
        },
      }).then((response) => {
        if (response.status === 201) {
          setProgressShow(false);
          response
            .json()
            .then((data) => ({ data: data, status: response.status }))
            .then((resp) => {
              setSuccessMessage(`${resp.data.incident_id}`);
            });
          setSuccessShow(true);
        } else if (response.status === 400) {
          setWarningShow(true);
          setButtonShow(true);
          setProgressShow(false);
          response
            .json()
            .then((data) => ({ data: data, status: response.status }))
            .then((resp) => {
              setWarningMessage(`${resp.status} - ${resp.data.message}`);
            });
        } else {
          setErrorShow(true);
          setButtonShow(true);
          setProgressShow(false);
          response
            .json()
            .then((data) => ({ data: data, status: response.status }))
            .then((resp) => {
              setErrorMessage(`${resp.status} - ${resp.data.message}`);
            });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  //  Recent incidents dialog:
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function ShowRecentIncidents() {
    setOpenDialog(true);
  }

  return (
    <Card
      sx={{
        m: 2,
        p: 0.5,
        borderLeft: '5px solid rgb(0, 20, 95)',
        marginTop: '100px',
      }}
    >
      <Divider />
      <CardContent>
        <Typography variant="h5">Puff Incident - Trigger a new incident</Typography>
        <Typography variant="subtitle2" color="#bbb">
          We will create some communication channels to help you with your incident.
        </Typography>
        <div id="createIncidentForm">
          <FormHelperText>
            <ArrowForwardIcon style={{ fontSize: 10 }} />{' '}
            <Link href="#" onClick={ShowRecentIncidents}>
              Show recent incidents
            </Link>{' '}
            <OpenInNewIcon style={{ fontSize: 10 }} />
          </FormHelperText>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <TextField
                required
                id="description"
                name="description"
                label="Description of the issue:"
                margin="dense"
                value={state.description}
                onChange={handleInputChange}
                sx={{ mt: 3 }}
              />
              <TextField
                value={state.severity}
                onChange={handleInputChange}
                select
                id="severity"
                name="severity"
                label="Severity:"
                margin="dense"
                sx={{ mt: 3 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="SEV-5">
                  <HelpOutlineOutlinedIcon style={{ marginRight: 10 }} /> <b>SEV-5</b> &nbsp;&nbsp;&nbsp;{' '}
                  <p style={{ fontSize: 10 }}>No cust/biz impact. I just need channels for a minor bug.</p>
                </MenuItem>
                <MenuItem value="SEV-4">
                  <InfoOutlinedIcon style={{ marginRight: 10 }} /> <b>SEV-4</b> &nbsp;&nbsp;&nbsp;{' '}
                  <p style={{ fontSize: 10 }}>No cust/biz impact. I just need channels for a small issue.</p>
                </MenuItem>
                <MenuItem value="SEV-3">
                  <WarningAmberOutlinedIcon style={{ marginRight: 10 }} /> <b>SEV-3</b> &nbsp;&nbsp;&nbsp;{' '}
                  <p style={{ fontSize: 10 }}>Minor cust/biz impact (less than 1%). I need some help.</p>
                </MenuItem>
                <Divider />
                <MenuItem value="SEV-2" sx={{ color: '#ff1744' }}>
                  <NotificationsActiveOutlinedIcon style={{ marginRight: 10 }} /> <b>SEV-2</b> &nbsp;&nbsp;&nbsp;{' '}
                  <p style={{ fontSize: 10 }}>Significant cust/biz impact (greater than 1%). Alert everyone NOW.</p>
                </MenuItem>
                <MenuItem value="SEV-1" sx={{ color: '#ff1744' }}>
                  <LocalFireDepartmentOutlinedIcon style={{ marginRight: 10 }} /> <b>SEV-1</b> &nbsp;&nbsp;&nbsp;{' '}
                  <p style={{ fontSize: 10 }}>Major cust/biz impact. ALL HANDS ON DECK.</p>
                </MenuItem>
              </TextField>
              <FormHelperText>
                Leave blank/None if not sure. Defaults to SEV-3.{' '}
                <Link href="https://gopuff.atlassian.net/wiki/spaces/ITOPS/pages/746815795/Severity+Levels">Severity Matrix Details</Link>
              </FormHelperText>

              <Autocomplete
                id="tags-standard"
                getOptionLabel={(option) => `${option.service_name} - (${option.team}) [Tier: ${option.tier}]`}
                multiple
                options={servicesSearchResults}
                renderInput={(params) => <TextField {...params} label="Impacted services:" />}
                sx={{ mt: 3 }}
                onChange={(event, value) => {
                  setServicesValue(value);
                }}
                value={servicesValue}
              />

              <FormHelperText>Type any service name or keywords to begin search.</FormHelperText>
              {buttonShow ? (
                <Box textAlign="center">
                  <Button type="submit" variant="contained" endIcon={<SendIcon />} sx={{ mt: 3, maxWidth: '75%' }}>
                    Submit{' '}
                  </Button>{' '}
                </Box>
              ) : null}
              {progressShow ? <LinearProgress color="inherit" sx={{ mt: 3 }} /> : null}
              {successShow ? (
                <Alert variant="filled" severity="success" sx={{ mt: 3 }}>
                  Incident triggered successfully <ArrowForwardIcon style={{ fontSize: 10 }} />{' '}
                  <Link href={'/incident/' + successMessage}>#Incident-{successMessage.split('\\')[0]}</Link>{' '}
                </Alert>
              ) : null}
              {warningShow ? (
                <Alert variant="filled" severity="warning" sx={{ mt: 3 }}>
                  {' '}
                  {warningMessage}{' '}
                </Alert>
              ) : null}
              {errorShow ? (
                <Alert variant="filled" severity="error" sx={{ mt: 3 }}>
                  {' '}
                  {errorMessage}{' '}
                </Alert>
              ) : null}
            </FormControl>
          </form>
        </div>
      </CardContent>

      <Divider />
      <CardContent>
        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span dangerouslySetInnerHTML={dialogContentText} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>OK</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default IncidentTrigger;
