import React, { useState } from 'react';
import { Chip, Container, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import IncidentCard from '../components/IncidentCard';
import useIncidents from '../hooks/useIncidents';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DaysSinceChip from '../components/DaysSinceChip';
import UptimeChart from '../components/UptimeChart';

export default function HomePage() {
  // After 1st login, Redirect to entry page.
  if (sessionStorage.getItem('entry.path')) {
    const path = sessionStorage.getItem('entry.path');
    window.location.replace(path); // TODO; figure out why navigate(path) doesn't work here
    sessionStorage.removeItem('entry.path');
  }

  const { items, loading } = useIncidents();
  const [active, setActive] = useState(false);
  const [stable, setStable] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);

  React.useEffect(() => {
    let filtered = items;
    if (active || stable || resolved || completed) {
      filtered = items.filter((item) => {
        const state = item.data.attributes.state;
        return (
          (active && state === 'active') ||
          (stable && state === 'stable') ||
          (resolved && state === 'resolved') ||
          (completed && state === 'completed')
        );
      });
    }
    setFilteredItems(filtered);
  }, [active, stable, resolved, completed, items]);

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;

    switch (name) {
      case 'active':
        setActive(checked);
        break;
      case 'stable':
        setStable(checked);
        break;
      case 'resolved':
        setResolved(checked);
        break;
      case 'completed':
        setCompleted(checked);
        break;
      default:
        break;
    }
  };

  return (
    <main style={{ paddingTop: 90 }}>
      <Container maxWidth="md">
        <Typography style={{ marginTop: 6, textAlign: 'center' }} variant="h5" component="h2" gutterBottom>
          Incident Status Page
        </Typography>
        <DaysSinceChip />
        <UptimeChart />
        <Typography sx={{ fontSize: '.8em', textAlign: 'center' }} color="text.secondary" gutterBottom>
          Major incidents from last 10 days
        </Typography>

        {!loading && (
          <div>
            <FormGroup
              row
              // for UX, let's not show the chart if the user is on a small screen / mobile device
              sx={{
                display: {
                  lg: 'flex',
                  md: 'flex',
                  sm: 'none',
                  xs: 'none',
                },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                control={<Checkbox checked={active} onChange={handleFilterChange} name="active" />}
                label={<Chip label="Active" color="error" />}
              />

              <FormControlLabel
                control={<Checkbox checked={stable} onChange={handleFilterChange} name="stable" />}
                label={<Chip label="Stable" color="warning" />}
              />

              <FormControlLabel
                control={<Checkbox checked={resolved} onChange={handleFilterChange} name="resolved" />}
                label={<Chip label="Resolved" color="success" />}
              />
              <FormControlLabel
                control={<Checkbox checked={completed} onChange={handleFilterChange} name="completed" />}
                label={<Chip label="Completed" color="info" variant="outlined" />}
              />
            </FormGroup>
            {filteredItems.length === 0 ? (
              <Typography sx={{ m: 4 }} style={{ textAlign: 'center' }}>
                No incidents reported
              </Typography>
            ) : (
              <>
                {filteredItems.map((i) => (
                  <IncidentCard key={i.data.attributes.public_id} item={i} />
                ))}
              </>
            )}
          </div>
        )}

        <Fab variant="extended" color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 40, right: 16 }} href="/create-incident">
          <AddIcon sx={{ mr: 1 }} />
          PUFF-INCIDENT
        </Fab>
      </Container>
    </main>
  );
}
