import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Typography, Divider, CardActions, Button, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { timeAgo, getSeverityColor, getStateExplanation, capitalize, getTitle, getStateVariant } from '../hooks/utils';
import ReactMarkdown from 'react-markdown';
import AddResponder from '../components/AddResponder';

function getColor(i) {
  if (i.data.attributes.fields.state.value === 'active') {
    return 'error';
  }
  if (i.data.attributes.fields.state.value === 'stable') {
    return 'warning';
  }
  if (i.data.attributes.fields.state.value === 'resolved') {
    return 'success';
  }
  if (i.data.attributes.fields.state.value === 'completed') {
    return 'info';
  }
  return 'primary';
}

IncidentDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

function IncidentDetails({ item }) {
  const i = item;

  return (
    <Card
      sx={{
        m: 2,
        p: 0.5,
        borderLeft: '5px solid rgb(0, 20, 95)',
      }}
    >
      <CardHeader
        title={
          <Typography variant="h6">
            Incident-
            {i.data.attributes.public_id}
          </Typography>
        }
        action={
          <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
            <Chip label={i.data.attributes.fields.severity.value} color={getSeverityColor(i)} />
            <Tooltip title={getStateExplanation(i)} placement="bottom">
              <Chip label={capitalize(i.data.attributes.fields.state.value)} color={getColor(i)} variant={getStateVariant(i)} />
            </Tooltip>
          </Stack>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="h6">{getTitle(i)}</Typography>
        <Typography sx={{ fontSize: '.8em', marginBottom: 1 }} color="text.secondary" gutterBottom>
          <Box sx={{ display: 'inline-flex', p: 1 }}>
            {'Created: ' +
              new Date(i.data.attributes.created).toLocaleTimeString() +
              ' - ' +
              new Date(i.data.attributes.created).toLocaleDateString()}
          </Box>

          <Box sx={{ display: 'inline-flex', p: 1 }}>
            {i.data.attributes.fields.state.value === 'resolved'
              ? 'Resolved: ' +
                new Date(i.data.attributes.resolved).toLocaleTimeString() +
                ' - ' +
                new Date(i.data.attributes.resolved).toLocaleDateString()
              : null}
          </Box>

          <Box sx={{ display: 'inline-flex', p: 1 }}>
            {i.data.attributes.customer_impact_end !== null
              ? 'Impact End: ' +
                new Date(i.data.attributes.customer_impact_end).toLocaleTimeString() +
                '- ' +
                new Date(i.data.attributes.customer_impact_end).toLocaleDateString()
              : null}
          </Box>
        </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }} gutterBottom>
          <Typography>SUMMARY:</Typography>
          <Box
            sx={{
              backgroundColor: '#f2f5f8',
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1">
              <ReactMarkdown>
                {i.data.attributes.fields.summary.value ? i.data.attributes.fields.summary.value : 'Summary not available'}
              </ReactMarkdown>
            </Typography>
          </Box>
        </Typography>

        <Typography style={{ whiteSpace: 'pre-wrap' }} sx={{ marginTop: 2 }} gutterBottom>
          <Typography>ROOT CAUSE:</Typography>
          <Box
            sx={{
              backgroundColor: '#f2f5f8',
              borderRadius: 2,
              p: 2,
            }}
          >
            <ReactMarkdown>
              {i.data.attributes.fields.root_cause.value ? i.data.attributes.fields.root_cause.value : 'Root cause not available'}
            </ReactMarkdown>
          </Box>
        </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }} gutterBottom>
          <Typography>
            TRIGGER:
            <Typography variant="body1">{i.data.attributes.fields.trigger.value ? i.data.attributes.fields.trigger.value : 'N/A'}</Typography>
          </Typography>
        </Typography>
        {i.data.attributes.customer_impact_start && (
          <Typography style={{ whiteSpace: 'pre-wrap' }} gutterBottom>
            <Typography>
              Impact Start:{' '}
              <Typography variant="body1">
                {new Date(i.data.attributes.customer_impact_start).toLocaleTimeString()}{' '}
                {new Date(i.data.attributes.customer_impact_start).toLocaleDateString()}
              </Typography>
            </Typography>
          </Typography>
        )}
        {i.data.attributes.customer_impact_end && (
          <Typography style={{ whiteSpace: 'pre-wrap' }} gutterBottom>
            <Typography>IMPACT END:</Typography>
            <Typography variant="body1">
              {new Date(i.data.attributes.customer_impact_end).toLocaleTimeString()}{' '}
              {new Date(i.data.attributes.customer_impact_end).toLocaleDateString()}
            </Typography>
          </Typography>
        )}
        <Typography style={{ whiteSpace: 'pre-wrap' }} gutterBottom>
          <Typography>UPDATE:</Typography>
          {i.data.attributes.fields.Announcement?.value ? (
            <>
              <Typography sx={{ fontSize: '.8em', marginBottom: 1 }} color="text.secondary">
                {timeAgo(i.data.attributes.modified)} ago @ {new Date(i.data.attributes.modified).toLocaleTimeString()}{' '}
                {new Date(i.data.attributes.modified).toLocaleDateString()}
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#f2f5f8',
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <ReactMarkdown>{i.data.attributes.fields.Announcement?.value}</ReactMarkdown>
              </Box>
            </>
          ) : (
            'Investigation is ongoing, we will post updates as soon as they become available'
          )}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          href={`https://gopuff.datadoghq.com/incidents/${i.data.attributes.public_id}`}
          target="_blank"
        >
          Open Datadog
        </Button>

        <AddResponder item={item} />
      </CardActions>
    </Card>
  );
}

export default IncidentDetails;
