import React from 'react';
import { useAuth } from 'oidc-react';
import PropTypes from 'prop-types';

export default function SessionProvider({ children }) {
  const auth = useAuth();
  window.sessionStorage.setItem('token', auth.userData?.id_token);
  window.sessionStorage.setItem('access_token', auth.userData?.access_token);
  window.sessionStorage.setItem('email', auth.userData?.profile?.name);
  return <div>{auth.userData ? children : null}</div>;
}

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
