import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { daysAgo } from './utils';

const { apiBaseUrl } = Config();
var endpointUrl = `${apiBaseUrl}/incidents`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
let cache = null;

export default function useIncidents(useFilter = true, incidentId = null, severity = null) {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      if (severity !== null) {
        endpointUrl = `${apiBaseUrl}/incident/${incidentId}`;
      }
      const config = { url: endpointUrl, headers };
      axios(config)
        .then((response) => {
          const { data } = response;
          const { incidents } = data.data.attributes;
          let items = incidents;
          if (useFilter) {
            items = incidents.filter((i) => {
              const { created } = i.data.attributes;
              return Number(daysAgo(created)) < 11;
            });
          }

          setItems(items);
          cache = items;
          setLoading(false);
        })
        .catch((err) => console.error(err));
    };

    if (cache) {
      setItems(cache);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [incidentId, severity, useFilter]);

  return { loading, items };
}
