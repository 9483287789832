import React from 'react';
import { useAuth } from 'oidc-react';
import Config from '../shared/Config';

export default function useRecentIncidents() {
  const defaultDialogContentText = { __html: 'Unable to fetch incident this time, please see Home Page for Ongoing critical Incidents.' };
  const [dialogContentText, setDialogContentText] = React.useState(defaultDialogContentText);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const auth = useAuth();

  React.useEffect(() => {
    //  Load services from Puff Directory:
    async function GetRecentIncidents() {
      // duration in minutes
      const duration = 30;

      const { puffIncidentUrl } = Config();
      const getRecentIncidentsUrl = `${puffIncidentUrl}/getrecentincidents?duration=${duration}`;
      const body = { call_origin: 'gostatus' };

      try {
        const getRecentIncidentsResp = await fetch(getRecentIncidentsUrl, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            token: auth.userData?.access_token,
          },
        });

        if (getRecentIncidentsResp.status === 200) {
          const recentIncidents = await getRecentIncidentsResp.json();
          let dialogText = '';
          if (recentIncidents.length > 0) {
            setDialogTitle(`Current ACTIVE incidents (Last ${duration} mins)`);

            for (const element of recentIncidents) {
              dialogText += `<span style="display: block; float:left; padding: 12px; margin:5px; border:1px solid #eee; border-radius: 5px; "><a href="/incident/${element['id']}" target="_blank">#incident-${element['id']}</a> : (${element['severity']}) : ${element['title']}</span> <br>`;
            }

            setDialogContentText({ __html: dialogText });
            setOpenDialog(true);
          } else {
            setDialogContentText({ __html: `No incidents in last ${duration} mins` });
          }
        }
      } catch (e) {
        console.log(e);
        setDialogContentText({
          __html: 'Sorry unable to get incidents right now. <br> You can view incidents at <a href="/">GoStatus Home Page.</a>',
        });
      }
    }
    GetRecentIncidents().catch((err) => {
      console.error(err);
    });
  }, [auth]);
  return { dialogContentText, setDialogContentText, dialogTitle, setDialogTitle, openDialog, setOpenDialog };
}
