import Config from '../shared/Config';
import { Chip, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { daysAgo } from '../hooks/utils';

export default function DaysSinceChip() {
  let daysSince = useRef(null);

  const auth = useAuth();
  const [chipShow, setChipShow] = useState(false);
  React.useEffect(() => {
    try {
      setChipShow(false);
      const { puffIncidentUrl } = Config();
      const endpointUrl = `${puffIncidentUrl}/incident/latest`;
      const headers = {
        'Content-Type': 'application/json',
        token: auth.userData?.access_token,
        method: 'POST',
      };
      const data = { call_origin: 'gostatus' };
      const config = { url: endpointUrl, headers, data: data, method: 'POST' };
      axios(config)
        .then((response) => {
          if (response.status == 200) {
            daysSince.current = Math.floor(Number(daysAgo(response.data)));
            setChipShow(true);
          } else {
            // do nothing basically, we just won't show the card if an error occurs
          }
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.log(e);
    }
  }, [auth]);
  return (
    <div>
      {chipShow ? (
        <Typography sx={{ fontSize: '.8em', textAlign: 'center' }} gutterBottom>
          <Chip color={ColorsForDays(daysSince.current)} label={`${daysSince.current} days without incident`} />
        </Typography>
      ) : null}
    </div>
  );
}

/**
 * @param {number} i
 * @returns {any}
 */
function ColorsForDays(i) {
  if (i <= 3) {
    return 'error';
  }
  // @ts-ignore
  if (3 < i <= 5) {
    return 'warning';
  }
  // @ts-ignore
  if (5 < i <= 7) {
    return 'info';
  }
  if (7 < i) {
    return 'success';
  } else {
    return 'secondary';
  }
}
