import React, { useState } from 'react';
import axios from 'axios';
import Config from '../shared/Config';
const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/incidents/chartdata`;

export default function useChartData() {
  const [loading, setLoading] = useState(true);
  /** @type {useState<any>} */
  const [chartData, setChartData] = useState();

  const dateValueFormatter = (date) =>
    date.toLocaleDateString('en-US', {
      month: 'short', // day: 'numeric',
      year: 'numeric',
    });

  React.useEffect(() => {
    const fetchData = async () => {
      return axios({ url: endpointUrl })
        .then((response) => {
          if (response.status == 200) {
            const resdata = response.data;
            const data = {
              xAxis: [
                {
                  id: 'dates',
                  scaleType: 'time',
                  valueFormatter: dateValueFormatter,
                  data: resdata.map((item) => {
                    return new Date(item.month);
                  }),
                },
              ],
              series: [
                {
                  label: 'Uptime (% of estimated missed orders)',
                  data: resdata.map((item) => {
                    return Number(Number(item.uptime).toFixed(2));
                  }),
                },
              ],
            };
            setChartData(data);
            setLoading(false);
          } else {
            // do nothing basically, we just won't show the graph if we're waiting / an error occurs
          }
        })
        .catch((err) => console.log(`we are in the axios error with err ${err}`));
    };

    fetchData();
  }, []);
  return { loading, chartData };
}
