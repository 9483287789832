import React from 'react';
import { Link, Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ padding: 2 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.gopuff.com/">
        SRE @ Gopuff
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
