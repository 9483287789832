import React from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { useAuth } from 'oidc-react';
import AppDrawer from './AppDrawer';

function getInitials(name) {
  let initials = '';
  name.split(' ').forEach((word) => {
    initials += word.charAt(0).toUpperCase();
  });
  return initials;
}

function AppNavigation() {
  const auth = useAuth();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton onClick={() => setDrawerOpen(true)} size="large" edge="start" aria-label="menu" sx={{ mr: 0 }}>
              <MenuIcon color="secondary" />
            </IconButton>
            <Typography
              variant="h6"
              color="secondary"
              component={Link}
              to="/"
              sx={{
                textDecoration: 'none',
                flexGrow: 1,
                display: { sm: 'block' },
              }}
            >
              GoStatus
            </Typography>
            {auth && auth.userData ? (
              <Avatar sx={{ bgcolor: '#fff', color: 'rgb(0, 20, 95)' }} aria-label="recipe">
                {getInitials(auth.userData.profile.name)}
              </Avatar>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <AppDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </>
  );
}

export default AppNavigation;
