import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Typography, Divider, CardActionArea, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { timeAgo, getSeverityColor, getStateExplanation, capitalize, getTitle, getStateVariant } from '../hooks/utils';
import ReactMarkdown from 'react-markdown';

function getColor(i) {
  if (i.data.attributes.fields.state.value === 'active') {
    return 'error';
  }
  if (i.data.attributes.fields.state.value === 'stable') {
    return 'warning';
  }
  if (i.data.attributes.fields.state.value === 'resolved') {
    return 'success';
  }
  if (i.data.attributes.fields.state.value === 'completed') {
    return 'info';
  }
  return 'primary';
}

IncidentCard.propTypes = {
  item: PropTypes.object.isRequired,
};

function IncidentCard({ item }) {
  const i = item;

  return (
    <Card
      key={i.data.attributes.public_id}
      sx={{
        m: 1,
        p: 0.5,
        borderLeft: '5px solid rgb(0, 20, 95)',
      }}
    >
      <CardActionArea href={`/incident/${i.data.attributes.public_id}`}>
        <CardHeader
          title={
            <Typography variant="h6">
              Incident-
              {i.data.attributes.public_id}
            </Typography>
          }
          action={
            <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
              <Chip label={i.data.attributes.fields.severity.value} color={getSeverityColor(i)} />
              <Tooltip title={getStateExplanation(i)} placement="bottom">
                <Chip label={capitalize(i.data.attributes.fields.state.value)} color={getColor(i)} variant={getStateVariant(i)} />
              </Tooltip>
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <Typography variant="h6">{getTitle(i)}</Typography>
          <Typography sx={{ fontSize: '.8em', marginBottom: 1 }} color="text.secondary" gutterBottom>
            Created: {new Date(i.data.attributes.created).toLocaleTimeString()} - {new Date(i.data.attributes.created).toLocaleDateString()}
          </Typography>
          <Typography variant={'subtitle1'}>UPDATE:</Typography>
          {i.data.attributes.fields.Announcement?.value ? (
            <>
              <Typography sx={{ fontSize: '.8em', marginBottom: 1 }} color="text.secondary">
                {timeAgo(i.data.attributes.modified)} ago @ {new Date(i.data.attributes.modified).toLocaleTimeString()}{' '}
                {new Date(i.data.attributes.modified).toLocaleDateString()}
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#f2f5f8',
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <ReactMarkdown>{i.data.attributes.fields.Announcement?.value}</ReactMarkdown>
              </Box>
            </>
          ) : (
            'Investigation is ongoing, we will post updates as soon as they become available'
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default IncidentCard;
