/**
 * @param {string} created_ts
 * @returns {string}
 */
export function timeAgo(created_ts) {
  const today = new Date();
  const createdOn = new Date(created_ts);
  const msInDay = 24 * 60 * 60 * 1000;
  const msInMin = 60 * 1000;
  const msInHr = 60 * 60 * 1000;
  const diff = +today - +createdOn;
  const days = diff / msInDay;
  const mins = diff / msInMin;
  const hrs = diff / msInHr; // Math.round(days.toFixed(2)*24)
  if (days > 1) {
    return `${days.toFixed(0)}d`;
  }
  if (hrs > 1) {
    return `${Math.round(Number(hrs.toFixed(2)))}hr(s)`;
  }
  return `${Math.round(Number(mins.toFixed(2)))}mins`;
}

/**
 * @param {string} created_ts
 * @returns {string}
 */
export function daysAgo(created_ts) {
  const today = new Date();
  const createdOn = new Date(created_ts);
  const msInDay = 24 * 60 * 60 * 1000;
  const diff = +today - +createdOn;
  const days = diff / msInDay;
  return `${days.toFixed(0)}`;
}

/**
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * @param {any} i
 * @returns {string}
 */
export function getTitle(i) {
  let { title } = i.data.attributes;
  if (title.toLowerCase().startsWith('puff-incident')) {
    title = title.split('Puff-Incident -')[1];
  }
  return title;
}

/**
 * @param {any} i
 * @returns {any}
 */
export function getSeverityColor(i) {
  if (i.data.attributes.fields.severity.value === 'SEV-1') {
    return 'error';
  }
  if (i.data.attributes.fields.severity.value === 'SEV-2') {
    return 'warning';
  }
  if (i.data.attributes.fields.severity.value === 'SEV-3') {
    return 'info';
  }
  return 'warning';
}

/**
 * @param {any} i
 * @returns {string}
 */
export function getStateExplanation(i) {
  if (i.data.attributes.fields.state.value === 'active') {
    return 'Incident affecting others.';
  }
  if (i.data.attributes.fields.state.value === 'stable') {
    return 'Incident no longer affecting others, but investigation ongoing';
  }
  if (i.data.attributes.fields.state.value === 'resolved') {
    return 'Incident no longer affecting others and investigation complete';
  }
  if (i.data.attributes.fields.state.value === 'completed') {
    return 'Investigation & Postmortem complete';
  }
  return 'Incident affecting others.';
}

/**
 * @param {any} i
 * @returns {any}
 */
export function getStateVariant(i) {
  if (i.data.attributes.fields.state.value === 'active') {
    return 'contained';
  }
  if (i.data.attributes.fields.state.value === 'stable') {
    return 'contained';
  }
  if (i.data.attributes.fields.state.value === 'resolved') {
    return 'contained';
  }
  return 'outlined';
}

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
