import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';

const { apiBaseUrl } = Config();
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default function useIncident(incidentId) {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const endpointUrl = `${apiBaseUrl}/incident/${incidentId}`;
    const fetchData = async () => {
      const config = { url: endpointUrl, headers };
      axios(config)
        .then((response) => {
          const { data } = response;
          const { incidents } = data.data.attributes;
          let items = incidents;
          setItems(items);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    };

    fetchData();
  }, [incidentId]);

  return { loading, items };
}
