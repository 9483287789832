import React from 'react';
import useChartData from '../hooks/useChartData';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LineChart } from '@mui/x-charts/LineChart';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: 'small',
    border: '1px solid #dadde9',
    justifyContent: 'bottom',
    display: 'flex',
  },
}));

const confluenceLink = 'https://gopuff.atlassian.net/wiki/spaces/GD/pages/3176333627/SRE+Uptime+Calculation';
const LinkHint = () => {
  return (
    <StyledTooltip
      title={
        <>
          See&nbsp;<a href={confluenceLink}> SRE Uptime Calculation </a>&nbsp;for more info on how this is calculated.
        </>
      }
    >
      <span>
        Uptime (% of estimated missed orders) <InfoOutlinedIcon fontSize="inherit" />
      </span>
    </StyledTooltip>
  );
};

export default function UptimeChart() {
  const theme = useTheme();
  const { loading, chartData } = useChartData();

  return (
    <div>
      {!loading ? (
        <>
          <LineChart height={200} xAxis={chartData.xAxis} series={chartData.series} />
        </>
      ) : (
        <Skeleton component={undefined} variant="rounded" height={200} />
      )}
    </div>
  );
}
