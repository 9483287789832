import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { colors } from './colors';
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 20, 95)',
    },
    secondary: {
      main: '#eee',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: colors.success,
    },
    info: {
      main: colors.info,
    },
  },
});

export default theme;
