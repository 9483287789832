import React from 'react';
import { useAuth } from 'oidc-react';
import Config from '../shared/Config';

export default function useServices(searchPattern = '') {
  const auth = useAuth();
  /** @type {useState<any[]>} */
  let [servicesSearchResults, setServicesSearchResults] = React.useState([]);
  React.useEffect(() => {
    //  Load services from Puff Directory:
    async function FetchServicesFromPuffDir() {
      const { puffDirUrl } = Config();
      let puffDirSearchServiceUrl = `${puffDirUrl}/api/services?verbose=true`;

      if (searchPattern != '') {
        puffDirSearchServiceUrl = `${puffDirUrl}/api/search/${searchPattern}?filter=services&status=active`;
      }
      let servicesOptions = [];

      try {
        const services = await fetch(puffDirSearchServiceUrl, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${auth.userData?.access_token}`,
          },
        });

        if (services.status == 200) {
          const results = await services.json();
          console.log(results.length + ' Services found for keyword ' + searchPattern);
          if (results.length > 0) {
            if (searchPattern == '') {
              for (const element of results) {
                if (element['status'] == 'Active') {
                  servicesOptions.push(element);
                }
              }
            } else {
              servicesOptions = results;
            }
            setServicesSearchResults(servicesOptions);
          }
        }
      } catch (e) {
        console.log('caught error:');
        console.log(e);
      }
    }
    FetchServicesFromPuffDir();
  }, [searchPattern, auth]);
  return { servicesSearchResults, setServicesSearchResults };
}
