import React from 'react';
import { useParams } from 'react-router-dom';
import IncidentDetails from '../components/IncidentDetails';
import useIncident from '../hooks/useIncident';

function IncidentPage() {
  const { incidentId } = useParams();
  const { loading, items } = useIncident(incidentId);
  const item = items.find((i) => i.data.attributes.public_id === Number(incidentId));

  return <div style={{ paddingTop: 90 }}>{!loading && <IncidentDetails item={item} />}</div>;
}

export default IncidentPage;
