import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { AuthProvider } from 'oidc-react';
import './App.css';
import HomePage from './pages/HomePage';
import Config from './shared/Config';
import SessionProvider from './Session';
import Copyright from './components/Copyright';
import AppNavigation from './components/AppNavigation';
import IncidentPage from './pages/IncidentPage';
import CreateIncident from './pages/CreateIncident';

export default function App() {
  const { openid } = Config();
  // For local env testing
  if (window.location.origin === 'http://localhost:3000') {
    openid.redirectUri = 'http://localhost:3000';
  }
  const oidcConfig = {
    onBeforeSignIn: () => {
      console.log('oidc onBeforeSignIn');
      console.log(process.env);
      const { pathname, search } = window.location;
      sessionStorage.setItem('entry.path', pathname + search);
      return '';
    },
    onSignIn: (user) => {
      console.log('oidc onSignIn');
      // window.location.replace(window.sessionStorage.getItem("entry.pathname_search"))
    },
    authority: openid.authority,
    clientId: openid.clientId,
    redirectUri: window.location.origin,
  };

  return (
    <div className="App">
      <AuthProvider {...oidcConfig}>
        <SessionProvider>
          <Router>
            <AppNavigation />
            <Container id="app-container" maxWidth="md" disableGutters={false}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/incident/:incidentId" element={<IncidentPage />} />
                <Route path="/incident/:incidentId/:severity" element={<IncidentPage />} />
                <Route path="/create-incident" element={<CreateIncident />} />
              </Routes>
              <Copyright />
            </Container>
          </Router>
        </SessionProvider>
      </AuthProvider>
    </div>
  );
}
